<template>
  <div class="CartItemRelatedService">
    <div class="CartItemRelatedService__header">
      <i :class="['CartItemRelatedService__header__icon', iconClass]"></i>
      <p class="CartItemRelatedService__header__title">
        {{ title }}
      </p>
    </div>
    <div class="CartItemRelatedService__items">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="CartItemRelatedService__items__item">
        <p class="CartItemRelatedService__items__item__title">
          {{ item.title }}
        </p>
        <div class="CartItemRelatedService__items__item__description">
          <CartBtnUnits
            v-if="shouldShowBtnUnits(item)"
            :isDisabledPlusBtn="hasReachedMaxUnits(item)"
            :hidePlusAndMinButtons="!!(item.costs?.length || shouldHideSelectors(item))"
            :hideSelectors="Boolean(item.custom_items)"
            :item="item"></CartBtnUnits>
          <p
            v-else-if="!isItemFree(item)"
            class="CartItemRelatedService__items__item__description__units">
            {{ item.units }}
            <span v-if="item.units === 1">{{ $t('cart.units.singular') }}</span>
            <span v-else>{{ $t('cart.units.plural') }}</span>
          </p>
          <span
            v-if="isItemFree(item)"
            class="CartItemRelatedService__items__item__description__price CartItemRelatedService__items__item__description__price--free">{{ $t('product.related_services.free') }}</span>
          <span
            v-else
            class="CartItemRelatedService__items__item__description__price">{{ item.price }} €</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import CartBtnUnits from './UI/CartBtnUnits.vue';
  import useCartActionsLogic from 'CommonComposables/useCartActionsLogic';

  defineProps({
    title: { type: String, required: true },
    iconClass: { type: String, required: true },
    items: { type: Array, required: true }
  });

  const { hasReachedMaxUnits } = useCartActionsLogic();

  function shouldHideSelectors(currentItem) {
    return currentItem.data_layer?.conf_type === 'motiondisplay' || !!currentItem.data_layer?.motion_childs || currentItem?.is_cost_installation_mandatory || currentItem?.mandatory;
  }

  function shouldShowBtnUnits(currentItem) {
    return !!(currentItem.reference && !shouldHideSelectors(currentItem));
  }

  function isItemFree(currentItem) {
    return currentItem.price === 0;
  }
</script>

<style lang="less" scoped>
  @import 'LessFiles/fontsSizes.less';

  .CartItemRelatedService {
    border: 1px solid #E4E7E4;

    &__header {
      display: flex;
      width: 100%;
      align-items: center;
      font-size: @font-size-s;
      padding: 1em 1.25em;
      border-bottom: 1px solid #E4E7E4;

      &__icon {
        display: block;
        margin-right: 1em;
      }

      &__title {
        font-size: @font-size-s;
        color: #242424;
        font-weight: 500;
      }
    }

    &__items {
      padding: 1em 1.25em;

      &__item {
        &:not(:first-of-type) {
          padding-top: 1em;
        }

        &__title {
          font-size: @font-size-s;
          color: #000000;
          font-weight: 500;
          padding-bottom: 0.25em;
        }

        &__description {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: @font-size-s;
          color: #000000;
          font-weight: 400;

          &__units {  
            text-transform: lowercase;
          }

          &__price {
            &--free {
              color: #008539;
              text-transform: capitalize;
            } 
          } 
        } 
      } 
    } 
  }
</style>
