<template>
  <main
    id="mainElement"
    class="main"
    :class="mainClass">
    <div
      class="_sticky-wrapper"
      :class="!isApp && !bandPlacement && 'band--empty'">
      <Transition name="main">
        <Sheet
          v-if="bandPlacement && bandPlacement?.html && !isBandHidden"
          :placement="bandPlacement"
          :container-class="null" />
      </Transition>
      <SearchApp
        v-if="shouldShowSearchApp"
        @scrolling="handleScroll" />
    </div>
    <div v-if="hasApiTemplateHeader">
      [HEAD]
    </div>

    <HeaderWeb
      :show-header="shouldShowHeader"
      :is-home="isHome"
      :mustShowHeaderSearchIcon="mustShowHeaderSearchIcon"
      @handle-fold="fold"
      @show-search="handlerShowSearch" />

    <div
      v-show="!showSearchContainer"
      class="main__content"
      :style="mainContentStyle">
      <slot />
    </div>
    <AccesibilityButton />
    <template v-if="shouldShowFooter">
      <Teleport
        v-if="hasMountedParallaxFooterContainer"
        to="#parallax_footer">
        <FooterWeb />
      </Teleport>
      <FooterWeb v-else />
    </template>
    <ToastMessage />
    <OIDCSession
      v-if="isProd"
      :is-server="isServer" />
    <InitialAlerts @cookiesAccepted="setBandVisibility" />
    <ChatWidget
      v-if="showChatWidget"
      :is-experts-chat="isExpertsChat"
      :open-chat-automatically="openChatAutomatically"
      :is-app-webview="isUrlForChatbotWebView" />
    <SearchContainer
      v-if="showSearchContainer"
      :isbnList="isbnList"
      :show-search-input="true"
      :save-active-url="true"
      :has-band="!isBandHidden"
      :is-home="isHome"
      :is-text-books-search="isTextBooksCategory"
      @close-search="handlerCloseSearch"
      @clean-isbn-list="resetIsbn" />
    <CustomerNotification v-if="isCustomerNotificationOpen" />
    <SizeGuideModal v-if="isSizeGuideModalOpen" />
    <div id="Layout-modal-customerNotification" />
    <div id="Layout-modal-fidelization" />
    <div id="Layout-modal-fidelization-external-link" />
    <div id="Layout-modal-financeCalculator" />
    <div id="Layout-modal-extraInfo" />
    <div id="Layout-modal-extraInfo-z-index-back-header" />
    <div id="Layout-modal-iframe" />
    <div id="Layout-modal-imageViewer" />
    <div id="Layout-modal-modalCookies" />
    <div id="Layout-modal-modalFilterFacet" />
    <div id="Layout-modal-modalFilterFacetTag" />
    <div id="Layout-modal-modalFilter" />
    <div id="Layout-modal-modalFilterSearch" />
    <div id="Layout-modal-modalMarket" />
    <div id="Layout-modal-modalSearch" />
    <div id="Layout-modal-modalSize" />
    <div id="Layout-modal-modalUser" />
    <div id="Layout-modal-modalWishlist" />
    <div id="Layout-modal-newsletter-designers" />
    <div id="Layout-modal-newsletter-feedback" />
    <div id="Layout-modal-productCustomization" />
    <div id="Layout-modal-searchSpeech" />
    <div id="Layout-modal-modalCategories" />
    <div id="Layout-modal-expert" />
    <Chat
      v-if="viewChat"
      :config-chat="configChat"
      :type="chatType"
      @close-chat="closeChat"
      @reloadPDPRecommendedByExpert="reloadPDPRecommendedByExpert" />
  </main>
</template>

<script>
  import { mapGetter, mapGetters, mapAction, mapMutation } from 'CommonUtils/store/state.js';
  import { useI18n } from 'vue-i18n';
  import useNavigatorDetect from 'CommonComposables/navigatorDetect';
  import useSearchBar from 'Composables/useSearchBar';
  import HeaderWeb from './Header/Header.vue';
  import FooterWeb from './Footer/Footer.vue';
  import OIDCSession from 'CommonComponents/OIDCSession/OIDCSession.vue';
  import Sheet from 'CommonComponents/Sheets/Render.vue';
  import UtilsAnalytics from 'CommonUtils/analytics.js';
  import UtilsPlacements from 'CommonUtils/placements.js';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import UtilExposer from 'CommonUtils/exposer.js';
  import AccesibilityButton from 'Components/Vendors/EqualWeb/Accesibility.vue';
  import ToastMessage from 'CommonComponents/ToastMessage/ToastMessage.vue';
  import ICON_NAMES from 'CommonUtils/iconNames';

  export default {
    name: 'Layout',
    components: {
      HeaderWeb,
      FooterWeb,
      Chat: defineAsyncComponent(() => import('Components/Chat/Chat.vue')),
      ChatWidget: defineAsyncComponent(() => import('IAChatComponents/ChatWidget.vue')),
      ToastMessage,
      OIDCSession,
      InitialAlerts: defineAsyncComponent(() => import('./InitialAlerts.vue')),
      Sheet,
      SearchApp: defineAsyncComponent(() => import('CommonComponents/Search/App/SearchApp.vue')),
      CustomerNotification: defineAsyncComponent(() => import('CommonComponents/Product/CustomerNotification.vue')),
      AccesibilityButton,
      SizeGuideModal: defineAsyncComponent(() => import('CommonComponents/SizeGuide/SizeGuideModal.vue')),
      SearchContainer: defineAsyncComponent(() => import('Components/Search/SearchContainer.vue')),
    },
    props: {
      isHome: { type: Boolean, default: false },
      leanLayout: { type: Boolean, default: false },
      hasLogoList: { type: Boolean, default: false },
      hasContextMenu: { type: Boolean, default: false },
      bandPlacement: { type: Object, default: null },
      showStickyInApp: { type: Boolean, default: false },
      hasToShowHeader: { type: Boolean, default: true },
      hasToShowFooter: { type: Boolean, default: true },
      isbnList: { type: Array, default: () => [] },
      setNewSearch: { type: Boolean, default: false },
      isTextBooksCategory: { type: Boolean, default: false },
      hasApiTemplateHeader: { type: Boolean, default: false },
      scrollOnTop: { type: Boolean, default: true},
    },
    emits: ['scrolling', 'reloadPDPRecommendedByExpert', 'resetIsbn'],
    setup(props, { emit }) {
      const { t } = useI18n();
      const showBreadcrumb = ref(false);
      const mainContentStyle = ref('');
      const configChat = reactive({});
      const viewChat = ref(false);
      const chatType = ref('');
      const hasCalledCartService = ref(false);
      const isMounted = ref(false);
      const showContent = ref(false);
      const segments = ref('');
      const mustShowBand = ref(false);
      const showSearchContainer = ref(false);

      const { isDesktopDeviceByHeaderAndResolution, isMobileDevice }   = useNavigatorDetect();
      const { mustShowHeaderSearchIcon, isScrollOnTop } = useSearchBar();

      const {
        sitebar,
        isApp,
        isNewApp,
        isEciAppFocus,
        isModal,
        isServer,
        isProd,
        breadcrumbs,
        isPdp,
        hasBrandCustomization,
        brandCustomization,
        isAMV,
        hasProductListBar,
        hasMountedParallaxFooterContainer,
        layoutOptions,
        getChatbotUsers,
        getBaseUrl,
        getChatbotSegments,
      } = mapGetters('page', {
        sitebar: 'getSiteBar',
        isApp: 'isApp',
        isNewApp: 'isNewApp',
        isEciAppFocus: 'isEciAppFocus',
        isModal: 'isModal',
        isServer: 'isServer',
        isProd: 'isProd',
        breadcrumbs: 'getBreadcrumbs',
        isPdp: 'getIsPdp',
        hasBrandCustomization: 'hasBrandCustomization',
        brandCustomization: 'getBrandCustomization',
        isAMV: 'getIsAMV',
        hasProductListBar: 'hasProductListBar',
        hasMountedParallaxFooterContainer: 'hasMountedParallaxFooterContainer',
        layoutOptions: 'getLayoutOptions',
        getChatbotUsers: 'getChatbotUsers',
        getBaseUrl: 'getBaseUrl',
        getChatbotSegments: 'getChatbotSegments',
      });

      const { getFlatRate, getCartLineItems, isCartLoading, user, loyaltyPoints, redirectUrlBasket } = mapGetters('cart', {
        getFlatRate: 'getShippingPrimeActivated',
        getCartLineItems: 'getCartLineItems',
        isCartLoading: 'getLoadingStatus',
        user: 'getUser',
        loyaltyPoints: 'getLoyaltyData',
        redirectUrlBasket: 'getRedirectUrlBasket',
      });

      const { isCustomerNotificationOpen, isSizeGuideModalOpen, isCartOpen } = mapGetters('uiState', {
        isCustomerNotificationOpen: 'getStatusCustomerNotification',
        isSizeGuideModalOpen: 'getStatusModalSizeGuide',
        isCartOpen: 'getStatusModalMarket',
      });

      const getPageScrollPosition = mapGetter('layout/getPageScrollPosition');

      const setSessionId = mapAction('page', ['setSessionId']);
      const showModal = mapAction('uiState', ['showModal']);
      const setPageScrollPosition = mapAction('layout', ['setPageScrollPosition']);
      const initUuid = mapMutation('uuid', ['initUuid']);
      const getCart = mapAction('cart', ['getCart']);
      const addMessage = mapAction('toastMessageState', ['addMessage']);

      const isCustomBrandEnabled = computed(() => {
        if (showSearchContainer.value) return false;
        return hasBrandCustomization.value && brandCustomization.value.enabled;
      });

      const hasCustomBrandLogo = computed(() => {
        return isCustomBrandEnabled.value && brandCustomization.value.customizations.logo === true;
      });

      const mainClass = computed(() => {
        return {
          _has_band: !isBandHidden.value,
          _has_logo_list: props.hasLogoList,
          _has_context_menu: props.hasContextMenu,
          _has_app_search: isApp.value && !isPdp.value && !isNewApp.value,
          _has_search_box: !mustShowHeaderSearchIcon.value,
          _has_product_list_bar: hasProductListBar.value,
          _has_breadcrumbs: breadcrumbs?.value?.length > 1,
          _is_modal: isModal.value,
          _is_app: isApp.value,
          _is_amv: isAMV.value,
          _is_plp: !isPdp.value,
          _is_pdp: isPdp.value,
          _show_sticky_in_app: props.showStickyInApp,
          [brandCustomization?.value?.className]: isCustomBrandEnabled.value,
          custom_logo: hasCustomBrandLogo.value,
        };
      });

      const isBandHidden = computed(() => {
        return isMounted.value ? UtilsPlacements.isHiddenByCDPSegments(props.bandPlacement) && !mustShowBand.value : true;
      });
      const shouldShowHeader = computed(() => {
        if (!props.hasToShowHeader) return false;

        return !isApp.value && !isModal.value && !isAMV.value;
      });

      const shouldShowFooter = computed(() => {
        if (!props.hasToShowFooter) return false;
        if (isMounted.value && isUrlForChatbot.value) return false;
        if (showSearchContainer.value) return false;
        if (isPdp.value && !isDesktopDeviceByHeaderAndResolution.value) return false;

        return !isApp.value && !isModal.value && !isAMV.value;
      });
      const shouldShowSearchApp = computed(() => {
        return ((isApp.value && !isNewApp.value) || isEciAppFocus.value) && !isPdp.value && !isModal.value;
      });
      const isUrlForChatbot = computed(() => {
        const currentURL = window.location.href;
        return currentURL.includes('/nuevo-buscador-eci');
      });
      const isUrlForChatbotWebView = computed(() => {
        const currentURL = window.location.href;
        return currentURL.includes('/asistente-ia-webview/');
      });
      const isExpertsChat = computed(() => {
        if (layoutOptions?.value?.show_chat_gpt_experts) return true;
        else return false;
      });
      const isRealExpertsTime = computed(() => {
        if (layoutOptions.value?.experts_time) return true;
        else return false;
      });
      const openChatAutomatically = computed(() => {
        return isUrlForChatbotWebView.value;
      });
      const loginUrl = computed(() => {
        const url = sitebar?.value?.links?.login;
        return url || '';
      });
      const userHasChabotSegment = computed(() => {
        if (!getChatbotSegments.value.length) return true;
        if (!segments.value) return false;

        const arrSegments = segments.value.split(',');
        if (!arrSegments || arrSegments.length === 0) return false;
        return arrSegments.some((elemento) => getChatbotSegments.value.includes(elemento));
      });
      const showChatWidget = computed(() => {
        if (!isMounted.value) return false;
        if (isModal.value && isApp.value && isUrlForChatbotWebView.value) return true;
        if (isModal.value) return false;
        if (isPdp.value && isExpertsChat.value) return true;
        if (isUrlForChatbot.value) return true;
        if (!layoutOptions?.value?.show_chat_gpt) return false;
        if (showSearchContainer.value) return false;
        if (userHasChabotSegment.value) return true;
        return false;
      });
      const shouldRedirectToBasketMobile = computed(() => {
        return isMobileDevice.value;
      });

      watch(isCartLoading, (newVal, oldVal) => {
        if (newVal === false && oldVal === true && !hasCalledCartService.value) {
          hasCalledCartService.value = true;
          const cookiesPolicy = UtilsCookies.getCookieValue('cookiesPolicy');
          const zipCode = UtilsCookies.getCookieValue('zip');
          const flatRate = getFlatRate.value;
          UtilsAnalytics.sendUserPush({
            ...user.value,
            cookiesPolicy,
            zipCode,
            flatRate,
            loyaltyPoints: loyaltyPoints.value,
          });

          showContent.value = true;
        }
      });

      watch(user, async (newVal) => {
        if (newVal && isUrlForChatbot.value) {
          if (getChatbotUsers.value.length === 0) return;
          const email = user.value.email;
          if (!email) {
            window.location.href = getBaseUrl.value + '/usuario-no-autorizado/';
            return;
          }
          const hashedEmail = await encryptSHA256Hash(email);
          if (!getChatbotUsers.value.includes(hashedEmail))
            window.location.href = getBaseUrl.value + '/usuario-no-autorizado/';
        }
      });

      watch(() => props.isbnList, () => {
        showSearchContainer.value = props.isbnList.length ? !showSearchContainer.value : showSearchContainer.value;
      });

      watch(() => props.scrollOnTop, (newVal) => {
        isScrollOnTop.value = newVal;
      })

      const setBandVisibility = (visibility) => {
        mustShowBand.value = visibility;
      };
      const setSegmentsFromLocalStorage = () => {
        const adobeSegments = localStorage.getItem('kxelcorteinglessa_segs');
        segments.value = adobeSegments;
        UtilsCookies.setCookie('kxelcorteinglessa_segs', adobeSegments);
      };
      const encryptSHA256Hash = async (input) => {
        const textAsBuffer = new TextEncoder().encode(input);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
        return hash;
      };

      const fold = (folded, navHeight) => {
        if (folded && navHeight) {
          mainContentStyle.value = `margin-top: ${navHeight}px;`;
        } else {
          mainContentStyle.value = '';
        }
      };
      const closeChat = () => {
        viewChat.value = false;
      };
      const openChat = (configChat, chatType) => {
        viewChat.value = false;
        nextTick(() => {
          configChat.value = configChat;
          viewChat.value = true;
          chatType.value = chatType;
        });
      };
      const handleScroll = (e) => {
        emit('scrolling', e);
      };
      const getCartLineItemsFromStore = () => {
        return getCartLineItems.value;
      };
      const createCookieExpertVendor = (nameCookie, cookieVal, days = 15) => {
        if (!nameCookie || !cookieVal) return;
        UtilsCookies.setCookie(nameCookie, cookieVal, days);
      };
      const reloadPDPRecommendedByExpert = (recommendedData) => {
        emit('reloadPDPRecommendedByExpert', recommendedData);
      };
      const toggleCart = () => {
        if (shouldRedirectToBasketMobile.value) {
          window.location.href = redirectUrlBasket.value;
          return;
        }
        showModal({
          name: ICON_NAMES.MARKER,
          status: !isCartOpen.value,
        });
      };
      const handlerShowSearch = () => {
        setPageScrollPosition({ x: window.scrollX, y: window.scrollY });
        showSearchContainer.value = true;
        document.body.style.overflow = 'hidden';
      };
      const handlerCloseSearch = () => {
        resetIsbn();
        showSearchContainer.value = false;
        document.body.style.overflow = 'auto';
        setTimeout(() => restorePageScrollPosition(), 50);
      };
      const resetIsbn = () => {
        emit('resetIsbn');
      };
      const restorePageScrollPosition = () => {
        const position = getPageScrollPosition.value;
        window.scrollTo(position.x, position.y);
      };
      const getCartAndOpenModal = () => {
        getCart();
        if (!isMobileDevice.value) {
          toggleCart();
        } else {
          addMessage(t('cart.info_added_successfully'));
        }
      };

      initUuid();
      provide('openChat', openChat);
      provide('closeChat', closeChat);

      onMounted(() => {
        if (!isServer.value) {
          document.addEventListener('AdobeSDKLoaded', setSegmentsFromLocalStorage);
          setSessionId(UtilsCookies.getCookie('session_id') || '');
        }
        if (!UtilsCookies.getCookie('msh.sid') && isUrlForChatbot.value) window.location.href = loginUrl.value;

        UtilExposer(
          ['placements', 'compositions', 'cdp'],
          'removeHiddenCDPSegments',
          UtilsPlacements.removeHiddenByCDPSegmentsAutofind,
        );
        UtilExposer(['cart', 'content'], 'getLineItems', getCartLineItemsFromStore);
        UtilExposer(['geci'], 'createCookie', createCookieExpertVendor);
        UtilExposer(['cart'], 'toggleCart', toggleCart);
        UtilExposer(['cart'], 'getCartAndOpenModal', getCartAndOpenModal);
        isMounted.value = true;
      });

      onUnmounted(() => {
        document.removeEventListener('AdobeSDKLoaded', setSegmentsFromLocalStorage);
      });

      return {
        showBreadcrumb,
        mainContentStyle,
        configChat,
        viewChat,
        chatType,
        hasCalledCartService,
        isMounted,
        showContent,
        segments,
        mustShowBand,
        showSearchContainer,
         mainClass,
        isApp,
        shouldShowSearchApp,
        shouldShowHeader,
        fold,
        handlerShowSearch,
        shouldShowFooter,
        isProd,
        setBandVisibility,
        showChatWidget,
        isCustomerNotificationOpen,
        isSizeGuideModalOpen,
        isBandHidden,
        hasMountedParallaxFooterContainer,
        handlerCloseSearch,
        resetIsbn,
        reloadPDPRecommendedByExpert,
        handleScroll,
        isRealExpertsTime,
        openChatAutomatically,
        isDesktopDeviceByHeaderAndResolution,
        isExpertsChat,
        isUrlForChatbotWebView,
        mustShowHeaderSearchIcon,
      }
    }
  };
</script>
<style lang="less">
  .main-content {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .main {
    &-enter-active,
    &-leave-active {
      transition: 0.25s;
      transition-timing-function: ease-out;
      transform: translateY(0);
      opacity: 1;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(130%);
      transition-timing-function: ease-in;
      transition: 0.2s;
      opacity: 0;
    }
  }
</style>
