import { mapGetter } from 'CommonUtils/store/state.js';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';

const useSearchBar = () => {
  const isMegadropOpen = mapGetter('uiState/getStatusModalMegadrop');
  const isHome = mapGetter('page/isHome');
  const isPlp = mapGetter('page/getIsPlp');
  const { isDesktopDevice } = useNavigatorDetect();
  const isScrollOnTop = ref(true);

  const mustShowHeaderSearchIcon = computed(() => {
    return false;
    const valor = !isDesktopDevice.value && (
      isMegadropOpen.value ||
      (
        !isHome.value &&
        (
          !isPlp.value ||
          (isPlp.value && !isScrollOnTop.value)
        )
      )
    );
    return valor
  });

  return {
    mustShowHeaderSearchIcon,
    isScrollOnTop,
  }
}

export default useSearchBar;